import React, { useState } from 'react';
import MainAgent from '../../components/layout/MainAgent';
import Menu from '../../components/layout/RightMenu';
import Footer from '../../components/layout/Footer';
import MainMenu from '../../components/layout/MainMenu'; // 새로운 컴포넌트 추가

const Home = () => {
  const [menuType, setMenuType] = useState('default');
  const [showAgent, setShowAgent] = useState(false); // 에이전트 토글 상태 추가

  return (
    <div style={styles.container}>
      <div style={styles.mainContent}>
        <MainMenu setMenuType={setMenuType} setShowAgent={setShowAgent} /> {/* setShowAgent 전달 */}
        {showAgent && <MainAgent />} {/* 에이전트 컴포넌트 토글 */}
      </div>
      <div style={styles.rightMenu}>
        <Menu menuType={menuType} />
        <Footer />
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    height: '100vh',
    width: '100vw',
    maxWidth: '1920px', // 최대 너비 설정
    maxHeight: '1080px', // 최대 높이 설정
    margin: '0 auto', // 화면 중앙 정렬
    overflow: 'hidden', // 내용이 넘치지 않도록 설정
  },
  mainContent: {
    top: '20%',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px',
    position: 'relative',
  },
  rightMenu: {
    width: '350px',
    backgroundColor: '#E6E6E6',
    display: 'flex',
    flexDirection: 'column',
  }
};

export default Home;
import React, { useState } from 'react';
import { auth } from '../../api/firebase/firebaseConfig';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../../hooks/useAuth';
import './Signup.css'; // 스타일 파일 추가
import { useNavigate } from 'react-router-dom'; // useNavigate 추가

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate(); // useNavigate 사용

  const handleSignup = async (e) => {
    e.preventDefault();
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      userCredential.user.getIdToken().then((token) => {
        localStorage.setItem('authToken', token);
        setIsAuthenticated(true); // 인증 상태 업데이트
        navigate('/', { replace: true }); // 홈으로 리다이렉트 (replace 옵션 추가)
      });
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <form onSubmit={handleSignup} className="signupForm">
      <div className="formGroup">
        <label>이메일:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="formInput"
        />
      </div>
      <div className="formGroup">
        <label>비밀번호:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="formInput"
        />
      </div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button type="submit" className="signupButton">회원가입</button>
    </form>
  );
};

export default Signup;
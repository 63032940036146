import React from 'react';

const SalaryRatioModalContent = () => {
  return (
    <div>
      퇴직금 내용
    </div>
  );
};

export default SalaryRatioModalContent;

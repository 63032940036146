import React from 'react';

const FileUploader = ({
  fileUploadComplete,
  handleFileUpload,
  handleSaveSettings,
  handleGetSettings // 설정값 가져오기 핸들러 추가
}) => {
  return (
    <div className="file-uploader">
      <label htmlFor="file-upload" className="file-upload-label">
        {fileUploadComplete ? '파일 선택 (완료)' : '파일 선택'}
      </label>
      <input
        id="file-upload"
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
        className="hidden-file-input"
      />
      <button className="save-settings-button" onClick={handleSaveSettings}>
        설정 저장하기
      </button>
      <button className="get-settings-button" onClick={handleGetSettings}>
        설정값 가져오기
      </button>
    </div>
  );
};

export default FileUploader;

import React, { useState, useRef } from 'react';
import Draggable from 'react-draggable';
import './MainAgent.css'; // CSS 파일 임포트

const MainAgent = () => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const draggableRef = useRef(null); // ref 추가

  const handleSendMessage = () => {
    if (inputMessage.trim() !== '') {
      setMessages([...messages, { text: inputMessage, isUser: true }]);
      setInputMessage('');
      // AI 응답 로직
    }
  };

  return (
    <Draggable nodeRef={draggableRef} bounds="parent">
      <div ref={draggableRef} className="chatBox" style={{ bottom: '25%', left: '1%' }}> {/* 초기 위치 수정 */}
        <div className="messagesContainer">
          {messages.map((message, index) => (
            <div key={index} className={message.isUser ? 'userMessage' : 'aiMessage'}>
              <div className={message.isUser ? 'balloonImage' : 'contentImage'}>
                {message.text}
              </div>
            </div>
          ))}
        </div>
        <div className="inputContainer">
          <textarea
            className="textarea"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="문의하기"
          />
          <button className="sendButton" onClick={handleSendMessage}>전송</button>
        </div>
      </div>
    </Draggable>
  );
};

export default MainAgent;
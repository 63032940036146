import React from 'react';

const PayrollTable = ({
  payrollList,
  handleWageChange,
  handleInsuranceChange
}) => {
  return (
    <div className="payroll-table-container">
      {payrollList.length > 0 && (
        <div>
          <h3>급여 대상자 리스트</h3>
          <table className="payroll-table">
            <thead>
              <tr>
                <th>번호</th>
                <th>이름</th>
                <th>업장</th>
                <th>직원번호</th>
                <th>시급</th>
                <th>포괄임금시급</th>
                <th>주휴</th>
                <th>연차</th>
                <th>사회보험적용여부</th>
              </tr>
            </thead>
            <tbody>
              {payrollList.map((payroll, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{payroll.name}</td>
                  <td>{payroll.place}</td>
                  <td>{payroll.employeeID}</td>
                  <td>
                    <input
                      type="number"
                      value={payroll.hourlyWage}
                      onChange={(e) => handleWageChange(index, 'hourlyWage', e.target.value)}
                      className="wage-input"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={payroll.inclusiveWage}
                      onChange={(e) => handleWageChange(index, 'inclusiveWage', e.target.value)}
                      className="wage-input"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={payroll.weeklyAllowance}
                      onChange={(e) => handleWageChange(index, 'weeklyAllowance', e.target.value)}
                      className="wage-input"
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={payroll.annualLeave}
                      onChange={(e) => handleWageChange(index, 'annualLeave', e.target.value)}
                      className="wage-input"
                    />
                  </td>
                  <td>
                    <label>
                      국
                      <input
                        type="checkbox"
                        checked={payroll.socialInsurance.국}
                        onChange={(e) => handleInsuranceChange(index, '국', e.target.checked)}
                      />
                    </label>
                    <label>
                      건
                      <input
                        type="checkbox"
                        checked={payroll.socialInsurance.건}
                        onChange={(e) => handleInsuranceChange(index, '건', e.target.checked)}
                      />
                    </label>
                    <label>
                      고
                      <input
                        type="checkbox"
                        checked={payroll.socialInsurance.고}
                        onChange={(e) => handleInsuranceChange(index, '고', e.target.checked)}
                      />
                    </label>
                    <label>
                      산
                      <input
                        type="checkbox"
                        checked={payroll.socialInsurance.산}
                        onChange={(e) => handleInsuranceChange(index, '산', e.target.checked)}
                      />
                    </label>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default PayrollTable;

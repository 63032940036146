import React, { useState } from 'react';

const DocumentsModalContent = () => {
  const [activeTab, setActiveTab] = useState('근로자 서식');

  const renderContent = () => {
    switch (activeTab) {
      case '근로자 서식':
        return (
          <div>근로자 서식 내용</div>
        );
      case '운영 관리 서식':
        return (
          <div>운영 관리 서식 내용</div>
        );
      // case '본인부담금 서식':
      //   return (
      //     <div>본인부담금 서식 내용</div>
      //   );
      // case '수급자 서식':
      //   return (
      //     <div>수급자 서식 내용</div>
      //   );
      default:
        return null;
    }
  };

  return (
    <div>
      <div style={styles.tabMenu}>
        <div style={activeTab === '근로자 서식' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('근로자 서식')}>근로자 서식</div>
        <div style={activeTab === '운영 관리 서식' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('운영 관리 서식')}>운영 관리 서식</div>
        {/* <div style={activeTab === '본인부담금 서식' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('본인부담금 서식')}>본인부담금 서식</div>
        <div style={activeTab === '수급자 서식' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('수급자 서식')}>수급자 서식</div> */}
      </div>
      <div style={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

const styles = {
  tabMenu: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
    cursor: 'pointer'
  },
  tab: {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9'
  },
  activeTab: {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#e6e6e6',
    fontWeight: 'bold'
  },
  content: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#fff'
  }
};

export default DocumentsModalContent;

import React, { useState } from 'react';

const AccountsManagement = () => {
  const [accounts, setAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountNickname, setAccountNickname] = useState('');
  const [balance, setBalance] = useState('');
  const [transactions, setTransactions] = useState([]);
  const [newTransaction, setNewTransaction] = useState({
    date: '',
    description: '',
    amount: '',
  });

  const addAccount = () => {
    if (accountNumber && accountNickname) {
      setAccounts([...accounts, { number: accountNumber, nickname: accountNickname, balance: 0 }]);
      setAccountNumber('');
      setAccountNickname('');
    }
  };

  const deleteAccount = () => {
    if (selectedAccount) {
      setAccounts(accounts.filter(account => account.number !== selectedAccount));
      setSelectedAccount('');
    }
  };

  const updateBalance = () => {
    if (selectedAccount && balance) {
      setAccounts(accounts.map(account => 
        account.number === selectedAccount ? { ...account, balance: parseFloat(balance) } : account
      ));
    }
  };

  const addTransaction = () => {
    if (selectedAccount && newTransaction.date && newTransaction.description && newTransaction.amount) {
      setTransactions([...transactions, { ...newTransaction, accountNumber: selectedAccount }]);
      setNewTransaction({ date: '', description: '', amount: '' });
    }
  };

  const fetchTransactions = () => {
    // Mock data for demonstration
    const mockData = [
      { date: '2024-07-08', description: '급여', amount: 3000000 },
      { date: '2024-07-09', description: '식비', amount: -50000 },
      { date: '2024-07-10', description: '교통비', amount: -30000 },
    ];
    setTransactions(mockData);
  };

  return (
    <div style={{padding: '1rem', display: 'flex', flexDirection: 'column', gap: '1rem'}}>
      <div style={{display: 'flex', gap: '0.5rem'}}>
        <input 
          type="text"
          placeholder="계좌번호" 
          value={accountNumber} 
          onChange={(e) => setAccountNumber(e.target.value)} 
        />
        <input 
          type="text"
          placeholder="계좌 별칭" 
          value={accountNickname} 
          onChange={(e) => setAccountNickname(e.target.value)} 
        />
        <button onClick={addAccount}>계좌 추가</button>
      </div>

      <div style={{display: 'flex', gap: '0.5rem'}}>
        <select 
          value={selectedAccount} 
          onChange={(e) => setSelectedAccount(e.target.value)}
        >
          <option value="">계좌 선택</option>
          {accounts.map(account => (
            <option key={account.number} value={account.number}>
              {account.nickname} ({account.number})
            </option>
          ))}
        </select>
        <button onClick={deleteAccount}>계좌 삭제</button>
      </div>

      <div style={{display: 'flex', gap: '0.5rem'}}>
        <input 
          type="number" 
          placeholder="잔액" 
          value={balance} 
          onChange={(e) => setBalance(e.target.value)} 
        />
        <button onClick={updateBalance}>잔액 수정</button>
      </div>

      <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}>
        <input 
          type="date" 
          value={newTransaction.date} 
          onChange={(e) => setNewTransaction({...newTransaction, date: e.target.value})} 
        />
        <input 
          type="text"
          placeholder="적요" 
          value={newTransaction.description} 
          onChange={(e) => setNewTransaction({...newTransaction, description: e.target.value})} 
        />
        <input 
          type="number" 
          placeholder="금액" 
          value={newTransaction.amount} 
          onChange={(e) => setNewTransaction({...newTransaction, amount: e.target.value})} 
        />
        <button onClick={addTransaction}>거래 추가</button>
      </div>

      <button onClick={fetchTransactions}>거래내역 불러오기</button>

      <table style={{borderCollapse: 'collapse', width: '100%'}}>
        <thead>
          <tr>
            <th style={{border: '1px solid black', padding: '0.5rem'}}>날짜</th>
            <th style={{border: '1px solid black', padding: '0.5rem'}}>적요</th>
            <th style={{border: '1px solid black', padding: '0.5rem'}}>금액</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <td style={{border: '1px solid black', padding: '0.5rem'}}>{transaction.date}</td>
              <td style={{border: '1px solid black', padding: '0.5rem'}}>{transaction.description}</td>
              <td style={{border: '1px solid black', padding: '0.5rem'}}>{transaction.amount}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AccountsManagement;
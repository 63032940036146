import React from 'react';

const PayrollResult = ({
  payrollResult,
  toggleDetails,
  handlePayrollResultChange
}) => {
  if (!payrollResult) return null;

  return (
    <div className="payroll-result">
      {payrollResult.map((payroll, index) => (
        <div key={index} className="payroll-item">
          <div className="payroll-summary" onClick={() => toggleDetails(index)}>
            <span>{payroll.name}</span>
            <span>{payroll.basicPay.toLocaleString()} 원</span>
            <span>{payroll.inclusivePay.toLocaleString()} 원</span>
            <span>{payroll.weeklyHolidayPay.toLocaleString()} 원</span>
            <span>{payroll.annualLeavePay.toLocaleString()} 원</span>
            <button className="complete-button">완료</button>
            <button className="print-button">출력</button>
          </div>
          {payroll.showDetails && (
            <div className="payroll-details">
              <div>
                <label>기본수당: </label>
                <input
                  type="number"
                  value={payroll.basicPay}
                  onChange={(e) => handlePayrollResultChange(index, 'basicPay', Number(e.target.value))}
                /> 원
              </div>
              <div>
                <label>포괄임금시급: </label>
                <input
                  type="number"
                  value={payroll.inclusivePay}
                  onChange={(e) => handlePayrollResultChange(index, 'inclusivePay', Number(e.target.value))}
                /> 원
              </div>
              <div>
                <label>주휴수당: </label>
                <input
                  type="number"
                  value={payroll.weeklyHolidayPay}
                  onChange={(e) => handlePayrollResultChange(index, 'weeklyHolidayPay', Number(e.target.value))}
                /> 원
              </div>
              <div>
                <label>연차수당: </label>
                <input
                  type="number"
                  value={payroll.annualLeavePay}
                  onChange={(e) => handlePayrollResultChange(index, 'annualLeavePay', Number(e.target.value))}
                /> 원
              </div>
              <div>
                <label>야간수당: </label>
                <input
                  type="number"
                  value={payroll.nightAllowance}
                  onChange={(e) => handlePayrollResultChange(index, 'nightAllowance', Number(e.target.value))}
                /> 원
              </div>
              <div>
                <label>휴일수당: </label>
                <input
                  type="number"
                  value={payroll.holidayAllowance}
                  onChange={(e) => handlePayrollResultChange(index, 'holidayAllowance', Number(e.target.value))}
                /> 원
              </div>
              <div>
                <label>연장수당: </label>
                <input
                  type="number"
                  value={payroll.overtimeAllowance}
                  onChange={(e) => handlePayrollResultChange(index, 'overtimeAllowance', Number(e.target.value))}
                /> 원
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PayrollResult;

import React, { useState } from 'react';
import './MainMenu.css';

// MainMenu 컴포넌트 정의
const MainMenu = ({ setMenuType, setShowAgent }) => { // setShowAgent 추가
  // 1. 메뉴 아이템 배열을 정의.
  const menuItems = [
    '일찾기', '직원찾기', '오늘의 소식',
    '출결관리', '운영관리', '나의 소식',
    '다이어리', '커뮤니티', '에이전트'
  ];

  // 2. 메뉴 아이템들을 순회하며 MenuItem 컴포넌트를 생성하고 렌더링.
  return (
    <div className="mainMenu">
      {menuItems.map((item, index) => (
        <MenuItem
          key={index}
          item={item}
          setMenuType={setMenuType}
          setShowAgent={setShowAgent} // setShowAgent 전달
        />
      ))}
    </div>
  );
};

// MenuItem 컴포넌트 정의
const MenuItem = ({ item, setMenuType, setShowAgent }) => {
  // 3. 마우스 위치 상태를 정의. (초기값은 { x: '50%', y: '50%' })
  const [mousePosition, setMousePosition] = useState({ x: '50%', y: '50%' });

  // 4. 아이템에 따른 스타일 클래스를 한 번만 설정.
  const [menuItemStyle] = useState(() => getMenuItemStyle(item));

  // 5. 웨이브 효과를 위한 랜덤한 애니메이션 딜레이와 지속 시간을 생성.
  const delay = Math.random() * 5; // 0초에서 5초 사이의 딜레이
  const duration = 10 + Math.random() * 5; // 10초에서 15초 사이의 지속 시간

  // 6. 마우스가 요소에 들어왔을 때 호출되는 함수.
  const handleMouseEnter = (e) => {
    // 6-1. 요소의 위치와 크기를 가져옵니다.
    const rect = e.currentTarget.getBoundingClientRect();
    // 6-2. 마우스의 X, Y 좌표를 요소 내에서의 백분율로 계산.
    const x = ((e.clientX - rect.left) / rect.width) * 100;
    const y = ((e.clientY - rect.top) / rect.height) * 100;
    // 6-3. 마우스 위치 상태를 업데이트하여 웨이브 효과의 중심을 설정.
    setMousePosition({ x: `${x}%`, y: `${y}%` });
  };

  // 7. 마우스가 요소에서 나갔을 때 호출
  const handleMouseLeave = () => {
    // 7-1. 마우스 위치를 중앙으로 초기화하여 웨이브 효과를 리셋.
    setMousePosition({ x: '50%', y: '50%' });
  };

  // 8. 호버 효과가 없는 아이템들을 정의. (현재는 비어 있음)
  const noHoverEffectItems = [
    // 호버 효과를 비활성화할 아이템을 여기에 추가.
  ];

  // 9. 현재 아이템이 호버 효과를 가지는지 여부를 판단.
  const hasHoverEffect = !noHoverEffectItems.includes(item);

  // 10. MenuItem 컴포넌트를 렌더링.
  return (
    <div
      className={`menuItem ${menuItemStyle}`} // 10-1. 아이템에 따른 스타일 클래스를 적용
      onMouseEnter={hasHoverEffect ? handleMouseEnter : null} // 10-2. 호버 효과가 있을 경우에만 마우스 이벤트 핸들러를 연결
      onMouseLeave={hasHoverEffect ? handleMouseLeave : null}
      onClick={() => {
        if (item === '커뮤니티') {
          window.open('https://open.kakao.com/o/gNpAyDQg', '_blank'); // 커뮤니티 클릭 시 새창으로 URL 링크 실행
        } else if (item === '일찾기') {
          setMenuType('employee'); // 일찾기 클릭 시 RightMenuEmployee로 변경
        } else if (item === '직원찾기') {
          setMenuType('employer'); // 직원찾기 클릭 시 RightMenuEmployer로 변경
        } else if (item === '운영관리') {
          setMenuType('default'); // 운영관리 클릭 시 기본 RightMenu로 변경
        } else if (item === '에이전트') {
          setShowAgent(prev => !prev); // 에이전트 토글
        }
      }}
    >
      {item} {/* 10-3. 아이템의 이름을 표시*/}
      <div
        className={`wave ${hasHoverEffect ? 'hover-effect' : ''}`} // 10-4. 웨이브 효과를 위한 클래스를 적용
        style={{
          // 10-5. 웨이브 효과의 애니메이션 딜레이와 지속 시간을 설정
          animationDelay: `${delay}s`,
          animationDuration: `${duration}s`,
          // 10-6. 웨이브 효과의 시작 위치를 설정. (마우스 위치 또는 중앙)
          backgroundPosition: hasHoverEffect ? `${mousePosition.x} ${mousePosition.y}` : 'center',
        }}
      ></div>
    </div>
  );
};

// getMenuItemStyle 함수를 컴포넌트 밖으로 이동
// 11. 아이템에 따라 스타일 클래스를 반환
const getMenuItemStyle = (item) => {
  // 11-1. 아이템 이름에 따라 특정 스타일 클래스를 반환
  switch(item) {
    case '커뮤니티': return 'menuItemCommunity';
    case '오늘의 소식': return 'menuItemNews';
    case '나의 소식': return 'menuItemMyNews';
    case '직원찾기': return 'menuItemFindEmployee';
    case '운영관리': return 'menuItemManagement';
    default: return `menuItem${Math.floor(Math.random() * 3)}`; // 0에서 2 사이의 랜덤한 숫자를 붙인 클래스 반환
  }
};

export default MainMenu;
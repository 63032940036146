import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from '../modals/Modal';
import evaluation from '../../assets/images/evaluation.png';
import scheduleImg from '../../assets/images/schedule.png';
import salaryImg from '../../assets/images/salary.png';
import personnelImg from '../../assets/images/salary_ratio.png';
import documentsImg from '../../assets/images/documents.png';
import accountImg from '../../assets/images/account.png';
import { auth } from '../../api/firebase/firebaseConfig';
import { signOut } from 'firebase/auth';
import './RightMenu.css';

const RightMenu = ({ menuType }) => {
  const [activeModal, setActiveModal] = useState(null);
  const navigate = useNavigate();

  const menuItems = {
    default: [
      { id: 'evaluation', label: '피드백 관리', imgSrc: evaluation },
      { id: 'schedule', label: '상근직 스케줄 관리', imgSrc: scheduleImg },
      { id: 'salary', label: '급여 관리', imgSrc: salaryImg },
      { id: 'personnel', label: '인건비 비율/ 퇴직금', imgSrc: personnelImg },
      { id: 'documents', label: '서식 관리', imgSrc: documentsImg },
      { id: 'account', label: '회계 관리', imgSrc: accountImg },
    ],
    employee: [
      // RightMenuEmployee의 메뉴 아이템 정의
    ],
    employer: [
      // RightMenuEmployer의 메뉴 아이템 정의
    ],
  };

  const openModal = (id) => setActiveModal(id);
  const closeModal = () => setActiveModal(null);

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // 로그아웃 성공 시 처리
        navigate('/auth'); // 인증 페이지로 리다이렉트
      })
      .catch((error) => {
        // 로그아웃 실패 시 에러 처리
        console.error('로그아웃 실패:', error);
      });
  };

  return (
    <div className="rightsideMenuContainer">
      <div className="rightsideMenu">
        {menuItems[menuType].map(item => (
          <button key={item.id} className="rightsideMenuItem" style={{ backgroundImage: `url(${item.imgSrc})` }} onClick={() => openModal(item.id)}>
            {/* 이미지 자체가 버튼 배경으로 사용되므로 텍스트는 숨김. */}
            <span className="rightsideHiddenText">{item.label}</span>
          </button>
        ))}
        <button className="rightsideLogoutButton" onClick={handleLogout}>로그아웃</button>
      </div>
      {activeModal && <Modal id={activeModal} onClose={closeModal} />}
    </div>
  );
};

export default RightMenu;
import React, { useState, useEffect } from 'react';
import { collection, getDocs, addDoc, query, where } from 'firebase/firestore';
import { firestore } from '../../../api/firebase/firebaseConfig'; // 파이어베이스 위치 반영
import { auth } from '../../../api/firebase/firebaseConfig'; // auth 가져오기
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const ScheduleModalContent = () => {
  const [activeTab, setActiveTab] = useState('시간제 직원 스케쥴');
  const [schedules, setSchedules] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [newSchedule, setNewSchedule] = useState({ time: '', name: '', contact: '', note: '' });

  const fetchSchedules = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        console.log('Fetching schedules for user:', user.uid);
        const q = query(collection(firestore, 'schedules'), where('userId', '==', user.uid));
        const snapshot = await getDocs(q);
        const fetchedSchedules = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setSchedules(fetchedSchedules);
        console.log('Fetched schedules:', fetchedSchedules);
      } catch (error) {
        if (error.code === 'permission-denied') {
          console.error('권한이 없습니다.');
        } else {
          console.error('스케줄을 가져오는 중 오류가 발생했습니다:', error);
        }
        setSchedules([]); // 에러 발생 시 빈 배열로 설정
      }
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSchedule({ ...newSchedule, [name]: value });
  };

  const handleAddSchedule = async () => {
    const user = auth.currentUser;
    if (user) {
      try {
        console.log('Adding schedule for user:', user.uid);
        await addDoc(collection(firestore, 'schedules'), {
          ...newSchedule,
          date: selectedDate,
          userId: user.uid // 사용자 UID 추가
        });
        console.log('Schedule added successfully');
        setNewSchedule({ time: '', name: '', contact: '', note: '' });
        await fetchSchedules(); // 스케줄 목록 다시 가져오기
      } catch (error) {
        console.error('스케줄을 추가하는 중 오류가 발생했습니다:', error);
      }
    } else {
      console.error('User is not authenticated');
    }
  };

  const renderContent = () => {
    switch (activeTab) {
      case '예약 관리':
        return (
          <div>
            <Calendar onChange={handleDateChange} value={selectedDate} />
            <div>
              <h3>예약된 일정</h3>
              {schedules.filter(schedule => new Date(schedule.date.toDate()).toDateString() === selectedDate.toDateString()).map(schedule => (
                <div key={schedule.id}>
                  <p>시간: {schedule.time}</p>
                  <p>예약자명: {schedule.name}</p>
                  <p>연락처: {schedule.contact}</p>
                </div>
              ))}
            </div>
            <div>
              <h3>스케줄 메모</h3>
              <input type="text" name="time" placeholder="시간" value={newSchedule.time} onChange={handleInputChange} />
              <input type="text" name="name" placeholder="예약자명" value={newSchedule.name} onChange={handleInputChange} />
              <input type="text" name="contact" placeholder="연락처" value={newSchedule.contact} onChange={handleInputChange} />
              <input type="text" name="note" placeholder="비고" value={newSchedule.note} onChange={handleInputChange} />
              <button onClick={handleAddSchedule}>추가</button>
            </div>
          </div>
        );
      case '시간제 직원 스케줄':
        return <div>시간제 직원 스케줄 내용</div>;
      case '상근 직원 스케줄':
        return <div>상근 직원 스케줄 내용</div>;
      default:
        return null;
    }
  };

  return (
    <div>
      <div style={styles.tabMenu}>
        <div style={activeTab === '예약 관리' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('예약 관리')}>예약 관리</div>
        <div style={activeTab === '시간제 직원 스케줄' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('시간제 직원 스케줄')}>시간제 직원 스케줄</div>
        <div style={activeTab === '상근 직원 스케줄' ? styles.activeTab : styles.tab} onClick={() => setActiveTab('상근 직원 스케줄')}>상근 직원 스케줄</div>
      </div>
      <div style={styles.content}>
        {renderContent()}
      </div>
    </div>
  );
};

const styles = {
  tabMenu: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
    cursor: 'pointer'
  },
  tab: {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f9f9f9'
  },
  activeTab: {
    padding: '10px 20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#e6e6e6',
    fontWeight: 'bold'
  },
  content: {
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#fff'
  }
};

export default ScheduleModalContent;

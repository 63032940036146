import React from 'react';
import EvaluationModalContent from '../services/managements/EvaluationModalContent';
import ScheduleModalContent from '../services/managements/ScheduleModalContent';
import SalaryModalContent from '../services/managements/salary/SalaryModalContent';
import SalaryRatioModalContent from '../services/managements/salary/SalaryRatioModalContent';
import DocumentsModalContent from '../services/managements/DocumentsModalContent';
import AccountsManagement from '../services_account/AccountsManagement';
import './Modal.css'; 

const Modal = ({ id, onClose }) => {
  const renderModalContent = (id) => {
    switch(id) {
      case 'evaluation':
        return <EvaluationModalContent />;
      case 'schedule':
        return <ScheduleModalContent />;
      case 'salary':
        return <SalaryModalContent />;
      case 'salary_ratio':
        return <SalaryRatioModalContent />;
      case 'documents':
        return <DocumentsModalContent />;
      case 'account':
          return <AccountsManagement />;
      default:
        return null;
    }
  };

  return (
    <div className="modalOverlay">
      <div className="agent">
        <span role="img" aria-label="agent">🤖</span> 안녕하세요! 케어샐러리입니다! 무엇을 도와드릴까요?
      </div>
      <div className="modal">
        <button className="closeButton" onClick={onClose}>X</button>
        <div>{renderModalContent(id)}</div>
      </div>
    </div>
  );
};

export default Modal;

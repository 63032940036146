import React, { useState } from 'react';
import Login from './Login';
import Signup from './Signup';
import './AuthTabs.css'; // 스타일링을 위한 CSS 추가

const AuthTabs = () => {
  const [activeTab, setActiveTab] = useState('login');

  return (
    <div className="authTabsContainer">
      <div className="tabs">
        <button
          className={`tabButton ${activeTab === 'login' ? 'active' : ''}`}
          onClick={() => setActiveTab('login')}
        >
          로그인
        </button>
        <button
          className={`tabButton ${activeTab === 'signup' ? 'active' : ''}`}
          onClick={() => setActiveTab('signup')}
        >
          회원가입
        </button>
      </div>
      <div className="tabContent">
        {activeTab === 'login' ? <Login /> : <Signup />}
      </div>
    </div>
  );
};

export default AuthTabs;
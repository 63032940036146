import React, { useState } from 'react';
import { auth } from '../../api/firebase/firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useAuth } from '../../hooks/useAuth';
import './Login.css'; // 스타일 파일 추가
import { useNavigate } from 'react-router-dom'; // useNavigate 추가

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setIsAuthenticated } = useAuth();
  const navigate = useNavigate(); // useNavigate 사용

  const handleLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        userCredential.user.getIdToken().then((token) => {
          localStorage.setItem('authToken', token);
          setIsAuthenticated(true); // 인증 상태 업데이트
          console.log('로그인 성공, 홈으로 리다이렉트 시도'); // 디버깅 로그 추가
          navigate('/', { replace: true }); // 홈으로 리다이렉트 (replace 옵션 추가)
        });
      })
      .catch((error) => {
        console.error('로그인 실패:', error);
        let errorMessage = '로그인 실패: ';
        switch (error.code) {
          case 'auth/invalid-email':
            errorMessage += '유효하지 않은 이메일입니다.';
            break;
          case 'auth/user-disabled':
            errorMessage += '사용이 중지된 계정입니다.';
            break;
          case 'auth/user-not-found':
            errorMessage += '존재하지 않는 계정입니다.';
            break;
          case 'auth/wrong-password':
            errorMessage += '잘못된 비밀번호입니다.';
            break;
          case 'auth/invalid-credential':
            errorMessage += '유효하지 않은 정보입니다.';
            break;
          default:
            errorMessage += error.message;
        }
        alert(errorMessage); // 에러 alert 추가
      });
  };

  return (
    <form onSubmit={handleLogin} className="loginForm">
      <div className="formGroup">
        <label>이메일:</label>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="formInput"
        />
      </div>
      <div className="formGroup">
        <label>비밀번호:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="formInput"
        />
      </div>
      <button type="submit" className="loginButton">로그인</button>
    </form>
  );
};

export default Login;
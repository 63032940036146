import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { getPayrolls } from '../../../../api/get/getPayrolls';
import './SalaryModalContent.css';
import FileUploader from './FileUploader';
import PayrollTable from './PayrollTable';
import PayrollResult from './PayrollResult';
import { useAuth } from '../../../../hooks/useAuth'; // useAuth 추가

const settingName = ['이름', 'Name'];
const settingTimeFR = ['시작 시간', 'Start Time'];
const settingTimeTO = ['종료 시간', 'End Time'];
const settingPlace = ['업장', 'Place'];
const settingEmployeeID = ['직원번호', 'Employee ID'];
const settingTimeDate = ['날짜', 'Date'];

const getFieldValue = (row, fieldNames) => {
  for (const field of fieldNames) {
    if (row[field]) {
      return row[field];
    }
  }
  return '';
};

const SalaryModalContent = () => {
  const { user } = useAuth(); // 사용자 정보 가져오기
  const [scheduleJson, setScheduleJson] = useState(null);
  const [payrollList, setPayrollList] = useState([]);
  const [activeTab, setActiveTab] = useState('대상자 리스트');
  const [settings, setSettings] = useState({
    "yearMonth": "202401",
    "weekEnds": 7,
    "nightStart": "22:00",
    "nightEnd": "06:00",
    "extendedOpt": 1,
    "standardDailyHours": 8,
    "hourlyPay": 10000,
    "hourlyPayTotal": 12000,
    "baseWorkerOpt": 0,
    "standardWeeklyHours": 40,
    "standardWeeklyDays": 5,
    "numAnnualLeaveDays": 2,
    "holidays": ["2024-01-01", "2024-01-15"]
  });
  const [fileUploadComplete, setFileUploadComplete] = useState(false);
  const [payrollResult, setPayrollResult] = useState(null);
  const [schedules, setSchedules] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (!file) {
      alert('파일을 선택해주세요.');
      return;
    }
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet, { defval: "", raw: false, dateNF: "yyyy-mm-dd", cellDates: true });

      // 파일 처리
      const userId = user ? user.uid : 'guest'; // userId 대체
      console.log(user);
      if (json.some(row => row['LTCP_OFFR_DT']) && userId !== 'admin') {
        alert('파일 형식을 확인해주세요.');
        return; // 처리 로직 중단
      }

      setScheduleJson(json);
      extractPayrollList(json);
      processSchedules(json);
      setFileUploadComplete(true);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleGetSettings = () => {
    alert('설정값을 가져왔습니다.');
  };

  const extractPayrollList = (json) => {
    const payrolls = [];
    const uniqueNames = new Set();
    let extractedTimeDate = '';

    for (const row of json) {
      const name = getFieldValue(row, settingName);
      const place = getFieldValue(row, settingPlace);
      const employeeID = getFieldValue(row, settingEmployeeID);
      const timeDate = getFieldValue(row, settingTimeDate);

      if (!name || !place || !employeeID || !timeDate) {
        alert('이름, 업장, 직원번호 또는 날짜 정보가 누락되었습니다. 파일을 확인해주세요.');
        break;
      }

      extractedTimeDate = timeDate;
      const payrollKey = `${name}_${place}_${employeeID}`;
      if (!uniqueNames.has(payrollKey)) {
        uniqueNames.add(payrollKey);
        payrolls.push({
          name,
          place,
          employeeID,
          hourlyWage: '',
          inclusiveWage: '',
          weeklyAllowance: '',
          annualLeave: '',
          socialInsurance: {
            국: false,
            건: false,
            고: false,
            산: false
          }
        });
      }
    }
    payrolls.sort((a, b) => a.name.localeCompare(b.name, 'ko-KR'));
    setPayrollList(payrolls);
    setSettings(prevSettings => ({
      ...prevSettings,
      timeDate: extractedTimeDate
    }));
  };

  const processSchedules = (json) => {
    const formatTime = (time) => {
      const timeString = time.toString().padStart(4, '0');
      const hours = timeString.slice(0, -2);
      const minutes = timeString.slice(-2);
      return `${hours}:${minutes}`;
    };

    const processedSchedules = json.map(row => {
      const excelDate = getFieldValue(row, settingTimeDate);
      let date = '';
      let day = '01';

      if (excelDate) {
        if (excelDate instanceof Date) {
          date = excelDate;
        } else if (typeof excelDate === 'number') {
          date = new Date(Math.round((excelDate - 25569) * 86400 * 1000));
        } else {
          date = new Date(excelDate);
        }
        if (!isNaN(date.getTime())) {
          day = date.getDate().toString().padStart(2, '0');
        } else {
          console.error('Invalid date:', excelDate);
        }
      }

      const timeStartRaw = getFieldValue(row, settingTimeFR);
      const timeEndRaw = getFieldValue(row, settingTimeTO);

      return {
        name: getFieldValue(row, settingName),
        identifierWorker: getFieldValue(row, settingEmployeeID),
        identifierPlace: getFieldValue(row, settingPlace),
        timeStart: formatTime(timeStartRaw || ''),
        timeEnd: formatTime(timeEndRaw || ''),
        timeDate: day,
        extraSettings: {
          code: 'B0001',
          param1: false
        }
      };
    });
    setSchedules(processedSchedules);
  };

  const handleCalculate = async () => {
    if (payrollList && Array.isArray(payrollList) && schedules.length > 0) {
      try {
        const payload = {
          config: settings,
          schedules: schedules
        };
        const response = await getPayrolls(payload);

        console.log('계산 결과', response);

        setPayrollResult(response);
      } catch (error) {
        console.error('Error fetching payrolls:', error);
      }
    } else {
      alert('엑셀 파일을 업로드하고 필요한 데이터를 확인해주세요.');
    }
  };

  const handleWageChange = (index, field, value) => {
    const updatedPayrollList = [...payrollList];
    updatedPayrollList[index][field] = value;
    setPayrollList(updatedPayrollList);
  };

  const handlePayrollResultChange = (index, field, value) => {
    const updatedPayrollResult = [...payrollResult];
    updatedPayrollResult[index][field] = value;
    setPayrollResult(updatedPayrollResult);
  };

  const handleInsuranceChange = (index, field, value) => {
    const updatedPayrollList = [...payrollList];
    updatedPayrollList[index].socialInsurance[field] = value;
    setPayrollList(updatedPayrollList);
  };

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const handleSaveSettings = () => {
    alert('설정이 저장되었습니다.');
    console.log('Saved settings:', settings);
  };

  const renderContent = () => {
    switch (activeTab) {
      case '대상자 리스트':
        return (
          <PayrollTable
            payrollList={payrollList}
            handleWageChange={handleWageChange}
            handleInsuranceChange={handleInsuranceChange}
          />
        );
      case '계산':
        return (
          <div>
            <button className="calculate-button" onClick={handleCalculate}>
              계산하기
            </button>
            <PayrollResult
              payrollResult={payrollResult}
              toggleDetails={toggleDetails}
              handlePayrollResultChange={handlePayrollResultChange}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const toggleDetails = (index) => {
    const updatedPayrollResult = [...payrollResult];
    updatedPayrollResult[index].showDetails = !updatedPayrollResult[index].showDetails;
    setPayrollResult(updatedPayrollResult);
  };

  return (
    <div className="salary-modal-content">
      <h2>엑셀 자료를 업로드 하거나, 스케쥴 가져오기를 눌러주세요</h2>
      <FileUploader
        fileUploadComplete={fileUploadComplete}
        handleFileUpload={handleFileUpload}
        handleSaveSettings={handleSaveSettings}
        handleGetSettings={handleGetSettings}
      />
      <div className="tab-menu">
        <div 
          className={activeTab === '대상자 리스트' ? 'active-tab' : 'tab'} 
          onClick={() => setActiveTab('대상자 리스트')}
        >
          대상자 리스트
        </div>
        <div 
          className={activeTab === '계산' ? 'active-tab' : 'tab'} 
          onClick={() => setActiveTab('계산')}
        >
          계산
        </div>
      </div>
      <div className="content">
        {renderContent()}
      </div>
    </div>
  );
};

export default SalaryModalContent;

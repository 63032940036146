import api from '../api';

export const getPayrolls = async (payload) => {
  try {
    const response = await api.post('/daysAndWeeks', payload);
    return response;
  } catch (error) {
    console.error('급여 정보 가져오기 실패:', error);
    throw error;
  }
};